/* eslint-disable @ngrx/no-typed-global-store */
/* eslint-disable @ngrx/no-multiple-actions-in-effects */
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';import { concatLatestFrom } from '@ngrx/operators';

import { Store } from '@ngrx/store';
import { catchError, delay, switchMap, map, of, tap } from 'rxjs';

import { AuthenticationService } from 'src/app/providers/services/authentication.service';
import { AuthActions } from '../actions/auth.actions';
import { ProfileFeature, Tenant } from 'src/app/models/auth/admin-tool.type';
import { SystemActions } from '../actions/system.actions';
import { selectActiveTenant, selectTokenDetails } from '../selectors/auth.selectors';
import { AppState } from '../states/app.state';
import { ProfileRole } from 'src/app/models/auth/admin-tool.type';
import { environment } from 'src/environments/environment';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { ClientConfig } from 'src/app/models/core/client-config.type';
import { ClientHttpService } from 'src/app/features/registries/client-registry/providers/services/client-http.service';
import { ResponseModel } from 'src/app/models/response-model.type';

@Injectable()
export class AuthEffects {
  private readonly authService: AuthenticationService = inject(AuthenticationService);
  private readonly clientHttpService: ClientHttpService = inject(ClientHttpService);
  private readonly actions$: Actions = inject(Actions);
  private readonly store: Store<AppState> = inject(Store);

  redirectToGateway$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.redirectToGateway),
        tap(() => this.authService.login())
      );
    },
    { dispatch: false }
  );

  changeActiveTenant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveTenant),
      tap(({ active_tenant }) => localStorage.setItem(`${environment.localStorageKeyPrefix}_tenant`, active_tenant.name)),
      concatLatestFrom(() => this.store.select(selectTokenDetails)),
      map(([{ active_tenant }, tokenDetails]) =>
        AuthActions.getUserRoles({ uniqueId: tokenDetails?.uniqueId ?? '', tenant: active_tenant.name })
      )
    );
  });

  changeActiveRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.setActiveRole),
      concatLatestFrom(() => this.store.select(selectActiveTenant)),
      map(([{ active_role }, tenant]) => AuthActions.getUserFeatures({ role: active_role.name, tenant }))
    );
  });

  userTenants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserTenants),
      switchMap(({ uniqueId }) =>
        this.authService.tenants(uniqueId).pipe(
          switchMap((tenants: Array<Tenant>) => {
            if (tenants.length) {
              const current_tenant: string | null = localStorage.getItem(`${environment.localStorageKeyPrefix}_tenant`);
              const active_tenant: Tenant = current_tenant ? { name: current_tenant, info: '' } : tenants[0];
              return [AuthActions.getUserTenantsSuccess({ uniqueId, tenants }), AuthActions.setActiveTenant({ active_tenant })];
            } else {
              return [AuthActions.setTokenDetails({ tokenDetails: <TokenDetails>{} }), SystemActions.redirectToCourtesyPage()];
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserTenantsFailure({ error })))
        )
      )
    );
  });

  userTenantsFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.getUserTenantsFailure, AuthActions.getUserRolesFailure),
        tap(({ error }) => {
          if (error.status === HttpStatusCode.NotFound) {
            // eslint-disable-next-line @ngrx/no-dispatch-in-effects
            this.store.dispatch(SystemActions.redirectToCourtesyPage());
          }
        })
      );
    },
    { dispatch: false }
  );

  userRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRoles),
      delay(200),
      switchMap(({ uniqueId, tenant }) =>
        this.authService.roles(uniqueId, tenant).pipe(
          map((roles: Array<ProfileRole>) => {
            if (roles.length) {
              return AuthActions.getUserRolesSuccess({ roles, tenant });
            } else {
              return SystemActions.redirectToCourtesyPage();
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserRolesFailure({ error })))
        )
      )
    );
  });

  userRolesSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserRolesSuccess),
      map(({ roles, tenant }) => AuthActions.getUserFeatures({ role: roles.at(0)?.name ?? '', tenant }))
    );
  });

  userFeatures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserFeatures),
      switchMap(({ role, tenant }) =>
        this.authService.features(role, tenant).pipe(
          map((features: Array<ProfileFeature>) => {
            if (features.length) {
              return AuthActions.getUserFeaturesSuccess({ features });
            } else {
              return SystemActions.redirectToCourtesyPage();
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.getUserFeaturesFailure({ error })))
        )
      )
    );
  });

  getUserFeatureSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getUserFeaturesSuccess),
      map(() => AuthActions.getClientConfig())
    );
  });

  getClientConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getClientConfig),
      switchMap(() =>
        this.clientHttpService.getClientConfig().pipe(
          map((response:  ResponseModel<ClientConfig>) => AuthActions.getClientConfigSuccess({config: response.data})),
          catchError((error: HttpErrorResponse) => of(AuthActions.getClientConfigFailure({ error })))
        )
      )
    );
  });

  setActiveAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.setActiveAccount),
        tap(() => this.authService.checkAndSetActiveAccount())
      );
    },
    { dispatch: false }
  );

  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.login),
        tap(() => this.authService.login())
      
      );
    },
    { dispatch: false }
   
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.authService.logout())
      );
    },
    { dispatch: false }
  );
}
